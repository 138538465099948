import React, { Fragment, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import BRMenu from "@govbr/dsgov/dist/components/menu/menu";
import Avatar from "../Avatar";
import NivelConta from "../NivelConta";
import { mostraModalAssinaturaDocumentoBronze } from "../../features/aplicacao/aplicacaoSlice";
import config from "../../config";
import "./styles.css";
import { localStorageLogout } from "../../scripts/localStoreUtil";
import NomeUsuario from "../NomeUsuario";

function addListenersGovBr() {
  const menuList = [];
  for (const brMenu of window.document.querySelectorAll(".br-menu")) {
    menuList.push(new BRMenu("br-menu", brMenu));
  }
}

function handleLogout() {
  localStorageLogout();
  window.location.href =
    config.govbrUrl +
    "/logout?post_logout_redirect_uri=" +
    config.govbrLougoutUrl;
}

const mapDispatchToProps = { mostraModalAssinaturaDocumentoBronze };

function HeaderMenu(props) {
  useEffect(() => {
    addListenersGovBr();
  });
  const usuario = useSelector((state) => state.usuarioStore.usuario);
  const history = useHistory();

  const escondeMenu = () => {
    const menu = document.getElementById("menu");
    menu.classList.remove("show");

    const botaoAbrirMenu = document.getElementById("botao-abrir_menu");
    botaoAbrirMenu.classList.remove("active");
  };

  const onAssinaDocumentoClick = () => {
    escondeMenu();
    if (usuario.isBronze) {
      props.mostraModalAssinaturaDocumentoBronze();
    } else {
      window.open(config.assinadorDigitalUrl, "_blank");
    }
  };

    return (
      <Fragment>
      <div className="header-avatar">
          <div id="menu" className="avatar dropdown">
              <Avatar/>
              {!isMobile && 
                  <div className="m-half cumprimento">
                      Olá, {!usuario.isCNPJ && <strong><NomeUsuario/></strong>}
                  </div>
              }
              <button id="botao-abrir_menu" className="br-button circle small" type="button" aria-label="Abrir Menu de usuário" data-toggle="dropdown">
                  <i className="fas fa-angle-down" aria-hidden="true"></i>
              </button>
              <div className="br-notification" style={{maxHeight:'70rem',  width: '30%',  overflowX:'Hidden', overflowY:'Hidden'}}>
                  <div className="notification-header" style={{padding:'0px 0px 0px 10px', borderBottom: '0px'}}>                          
                      <div  className='mb-3 mt-3'>
                          <span className='cumprimento'>Olá, {!usuario.isCNPJ && <b><NomeUsuario nomeCompleto={true}/></b>}</span>
                      </div>
                      {!usuario.isCNPJ && 
                          <NivelConta clicavel={true} onClick={() => {
                                                                      escondeMenu(); 
                                                                      history.push('/')
                                                                  }}/>
                      }
                  </div>

                  <div className="notification-body" aria-hidden="true">
                      <div className="br-menu push active">
                          <div className="menu-container" style={{maxHeight:'65rem',position:'relative', overflowX:'Hidden', overflowY:'auto'}}>
                              <div className="menu-panel h-auto"   style={{maxHeight:'40rem'}}>
                                  <nav className="menu-body">
                                      <div className="menu-folder">
                                          
                                          <div className="menu-group">
                                              <span className="content" >Minha Conta</span>
                                          </div>
                                          <div className="br-divider my-1" style={{marginLeft:'2%',marginRight:'2%'}}></div>    
                                          <ul style={{paddign:'0'}}>
                                          { !usuario.isCNPJ && (
                                          <>
                                              <li>
                                                  <a className="menu-item" href={config.govbrContasDadosPessoaisUrl}><span className="content">Dados pessoais</span></a>
                                              </li>
                                              <li>
                                                  <a className="menu-item" href={config.govbrContasSegurancaUrl}><span className="content">Segurança da conta</span></a>
                                              </li>
                                              <li>
                                                  <a className="menu-item" href={config.govbrContasPrivacidadeUrl}><span className="content">Privacidade</span></a>
                                              </li>
                                          </>
                                          )}
                                          
                                              { usuario.isCNPJ && 
                                                  <li>
                                                      <a className="menu-item" href={config.govbrVincularEmpresasUrl}><span className="content">Vincular empresas via e-CNPJ</span></a>
                                                  </li>}
                                          </ul>
                                          
                                        
                                          <div className="menu-group">
                                              <span className="content">Serviços</span>
                                          </div>
                                          <span className="br-divider my-1" style={{marginLeft:'2%',marginRight:'2%'}}></span>    
                                          <ul>                                               
                                              { !usuario.isCNPJ && (
                                                  <>
                                                      <li> 
                                                          <a className="menu-item" href={'./carteira'}><span className="content">Carteira de Documentos</span></a>                                                        
                                                      </li>
                                                      <li>
                                                          <a className="menu-item" href={'./certidoes'}><span className="content">Baixar certidões</span></a>
                                                      </li>
                                                      <li>
                                                          <a className="menu-item" onClick={() => onAssinaDocumentoClick()}><span className="content">Assinar documentos digitalmente</span></a>
                                                      </li>
                                                      <li>                                                        
                                                          <a className="menu-item" href={'./notificacoes'}><span className="content">Notificações</span></a>
                                                      </li>
                                                      <li>                                                        
                                                          <a className="menu-item" href={'./consultarservicossolicitados'}>
                                                              <span className="content">Consultar serviços solicitados</span>
                                                          </a>
                                                      </li>
                                                      <li>
                                                        <a href="/agenda" className="menu-item">
                                                          <span className="content">Agenda GOV.BR</span>
                                                        </a>
                                                      </li>
                                                  </>
                                              )}
                                              {/* <li>
                                                  <a className="menu-item" onClick={() => escondeMenu()} href={config.servicosSolicitadosUrl} target="_blank"><span className="content">Serviços solicitados</span></a>
                                              </li> */}                                                   
                                              <li>
                                                  <a className="menu-item" onClick={() => escondeMenu()} href={config.faqGovernoDigitalUrl} target="_blank" rel="noreferrer">
                                                      <span className="content">Ajuda da conta gov.br</span>
                                                  </a>
                                              </li>                                                
                                          </ul>
                                          <div className="MenuBotao">
                                          <ul>                                               
                                              <li> 
                                                   <button type="button" className="menu-item" onClick={handleLogout}>
                                                   <span className="btn-secondary"  >Sair da conta</span></button>
                                                  
                                            </li></ul>
                                            </div> 
                                      </div>  
                                  </nav>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </Fragment>
  );
  
}

export default connect(null, mapDispatchToProps)(HeaderMenu);
