import React, { useMemo } from "react";
import { Container } from "./styles";
import { useRolarPagina } from "../../../hooks/useRolarPagina";
import { duracaoEventoEmDias } from "../utils";
import "./styles.css";

function ComponenteEvento(props) {
  const { event } = props;
  const { rolarParaEvento } = useRolarPagina();

  const handleOnClickEvento = () => {
    rolarParaEvento(`${event["id_evento"]}-${event["id_servico"]}`);
  };
  const eventoDiaUnico = useMemo(() => {
    if (duracaoEventoEmDias(event) < 1) {
      return true;
    }
    return false;
  }, [event]);

  return (
    <Container onClick={handleOnClickEvento} eventoDiaUnico={eventoDiaUnico}>
      <span title={event["titulo_servico"]}>{event["titulo_servico"]}</span>
    </Container>
  );
}

export default ComponenteEvento;
