import React, { useEffect } from "react";
import { Container } from "./styles";

function CalendarToolbar(props) {
  useEffect(() => {
    props.onView(props.view);
  }, []);

  return (
    <Container>
      <button type="button" className="br-button tertiary circle xsmall">
        <i
          className="fa fa-chevron-left"
          aria-hidden="true"
          onClick={() => props.onNavigate("PREV")}
          title="Botão ir para o mês anterior"
        />
        <span className="sr-only">Botão ir para o mês anterior</span>
      </button>

      <h4>{props.label}</h4>

      <button type="button" className="br-button tertiary circle xsmall">
        <i
          className="fa fa-chevron-right"
          aria-hidden="true"
          onClick={() => props.onNavigate("NEXT")}
          title="Botão ir para o próximo mês"
        />
        <span className="sr-only">Botão ir para o próximo mês</span>
      </button>
    </Container>
  );
}

export default CalendarToolbar;
