import { React, useCallback, useMemo } from "react";
import { Calendar, Views, luxonLocalizer } from "react-big-calendar";
import CalendarToolbar from "../CalendarToolbar";
import Event from "../ComponenteEvento";
import { DateTime } from "luxon";
import { LANGUAGE } from "../../../services/i18n";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./override-big-calendar-styles.css";
import { useRolarPagina } from "../../../hooks/useRolarPagina";

const localizer = luxonLocalizer(DateTime);

function ComponenteCalendario(props) {
  const VIEWS = useMemo(() => [Views.MONTH], []);
  const DEFAULT_VIEW = useMemo(() => Views.MONTH, []);

  const formats = useMemo(
    () => ({
      monthHeaderFormat: (date, culture, localizer) =>
        localizer.format(date, `MMMM, yyyy`, culture),
      weekdayFormat: (date, culture, localizer) =>
        localizer.format(date, "EEE").replace(/\./, ""),
    }),
    []
  );

  const components = useMemo(
    () => ({
      toolbar: (props) => <CalendarToolbar {...props} />,
      event: ({ event }) => <Event event={event} />,
    }),
    []
  );

  const { rolarParaEvento } = useRolarPagina();

  const handleOnShowMore = useCallback(
    (events) => {
      if (events && Array.isArray(events) && events.length > 0) {
        rolarParaEvento(events[0]["uid_evento"]);
      }
    },
    [rolarParaEvento]
  );

  return (
    <div style={{ flex: "1" }}>
      <Calendar
        defaultDate={DateTime.local().toJSDate()}
        localizer={localizer}
        culture={LANGUAGE}
        events={props.eventos}
        startAccessor="start"
        endAccessor="end"
        views={VIEWS}
        defaultView={DEFAULT_VIEW}
        onDrillDown={() => null}
        onShowMore={handleOnShowMore}
        components={components}
        formats={formats}
        showAllEvents={true}
        messages={{
          showMore: (count) => `+ ${count} ${count > 1 ? "eventos" : "evento"}`,
        }}
        {...props}
      />
    </div>
  );
}

export default ComponenteCalendario;
