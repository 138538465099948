import styled, { css } from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-scale-base) 0 var(--spacing-scale-base);

  > span {
    width: 100%;
    text-align: center;

    ${(props) => {
      if (!props.eventoDiaUnico) {
        return css`
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `;
      }

      //Line clamp. See: https://tailwindcss.com/docs/line-clamp
      return css`
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: normal;
        line-height: var(--font-lineheight-low);
      `;
    }}

    @media (max-width: 575px) {
      text-align: left;
    }
  }

  > span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: var(--surface-rounder-sm);
    background-color: var(--gray-5);
    height: 100%;

    border-left: 5px solid var(--blue-warm-40);
  }
`;
