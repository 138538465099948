import React, { Fragment, useCallback, useEffect, useState } from "react";
import { DateTime } from "luxon";
import Header from "../Header";
import HeaderPagina from "../HeaderPagina";
import { connect } from "react-redux";
import {
  exibeMensagemSucesso,
  exibeMensagemErro,
  exibeEmProcessamento,
  escondeEmProcessamento,
} from "../../features/aplicacao/aplicacaoSlice";
import ComponenteCalendario from "./ComponenteCalendario";
import { useMemo } from "react";
import ListaAgenda from "./ListaAgenda";
import { ScrollPageProvider } from "../../hooks/useRolarPagina";
import BotaoRolarParaTopo from "./BotaoRolarParaTopo";
import ModalEmProcessamento from "../ModalEmProcessamento";
import { useQuery } from "../../hooks/useParams";
import { getEventosDoMes } from "../../services/getEventosDoMes";
import { BreadCrumb } from "../BreadCrumb2";
const mapDispatchToProps = {
  exibeMensagemSucesso,
  exibeMensagemErro,
  exibeEmProcessamento,
  escondeEmProcessamento,
};

export function PaginaAgendaGovBr(props) {
  const caminhoBreadcrumb = useMemo(() => [{ texto: "Agenda" }], []);
  const [eventos, setEventos] = useState();
  const { query, setQuery } = useQuery();

  const [dataAtual, setDataAtual] = useState(() => {
    if (query.has("mes")) {
      try {
        const mesDaUrl = query.get("mes");
        return DateTime.local().set({ month: mesDaUrl }).toJSDate();
      } catch (err) {
        return new Date();
      }
    }

    return new Date();
  });

  const onNavigate = useCallback(
    (date) => {
      const mesAtual = DateTime.fromJSDate(date).month;
      setQuery({
        mes: mesAtual,
      });
      setDataAtual(date);
    },
    [setQuery]
  );

  useEffect(() => {
    if (!query.has("mes")) {
      setQuery({
        mes: DateTime.fromJSDate(dataAtual).month,
      });
    }
  });

  const onRangeChange = ({ start: dataInicio, end: dataFim }) => {
    props.exibeEmProcessamento({ texto: "Carregando agenda de serviços" });

    getEventosDoMes(dataInicio, dataFim)
      .then((eventos) => {
        setEventos(eventos);
      })
      .catch(() => {
        props.exibeMensagemErro({ titulo: "Erro ao buscar eventos!" });
      })
      .finally(() => {
        props.escondeEmProcessamento();
      });
    getEventosDoMes(dataInicio, dataFim).finally(() => {
      props.escondeEmProcessamento();
    });
  };

  return (
    <ScrollPageProvider>
      <Fragment>
        <Header />
        <div className="container-lg" style={{ position: "relative" }}>
          <BreadCrumb
            links={[
              {
                href: "/agenda",
                titulo: "Agenda",
                tooltip: "Link da página de agenda",
              },
            ]}
          />
          <div className="cabecalho_painel d-flex flex-column mt-4">
            <div className="row">
              <div className="col">
                <p className="mb-3xh">
                  Calendário dos principais serviços públicos do Brasil.
                </p>
              </div>
            </div>

            <ModalEmProcessamento />

            <div className="row">
              <div className="col">
                <ComponenteCalendario
                  onRangeChange={onRangeChange}
                  eventos={eventos}
                  onNavigate={onNavigate}
                  date={dataAtual}
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <ListaAgenda dataAtual={dataAtual} eventos={eventos} />
              </div>
            </div>
          </div>

          <BotaoRolarParaTopo />
        </div>
      </Fragment>
    </ScrollPageProvider>
  );
}

export default connect(null, mapDispatchToProps)(PaginaAgendaGovBr);
