import { useEffect } from "react";
import BRBreadcrumb from "@govbr/dsgov/dist/components/breadcrumb/breadcrumb";
import "./styles.css";

/**
 * Componente criado com base nas especificações do DSGOV v3.5.1
 * Referência: https://www.gov.br/ds/components/breadcrumb?tab=designer
 */
export function BreadCrumb(props) {
  const { links } = props;

  useEffect(() => {
    const breadcrumbList = [];
    for (const brBreadcrumb of window.document.querySelectorAll(
      ".br-breadcrumb"
    )) {
      breadcrumbList.push(new BRBreadcrumb("br-breadcrumb", brBreadcrumb));
    }
  }, []);

  return (
    <nav className="br-breadcrumb" aria-label="Breadcrumbs">
      <ol className="crumb-list">
        <li className="crumb home">
          <a className="br-button circle" href="/">
            <span className="sr-only">Página inicial</span>
            <i className="fas fa-home"></i>
          </a>
        </li>
        {links.map((link, i) => {
          const isPaginaAtual =
            i === links.length - 1
              ? { "data-active": "active", "aria-current": "page" }
              : {};

          return (
            <li
              className="crumb"
              {...isPaginaAtual}
              key={Math.random().toString(16).substring(2)}
            >
              <i className="icon fas fa-chevron-right"></i>
              {i === links.length - 1 ? (
                <span tabIndex="0" aria-current="page">
                  {link.titulo}
                </span>
              ) : (
                <a href={link.href}>{link.titulo}</a>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
