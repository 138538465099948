import { DateTime } from "luxon";

/**
 * Dada uma data, esta função retorna uma string no formato "Mês, ano", sendo ano
 * um inteiro de 4 dígitos.
 *
 * @param {Date} data data a ser formatada
 */
export function formatarDataMesAno(data) {
  if (!data) return undefined;

  const ano = data.getFullYear();
  let mes = data.toLocaleString("pt-br", { month: "long" });
  mes = mes.charAt(0).toUpperCase() + mes.slice(1);
  return `${mes}, ${ano}`;
}

export function mapearParaRBC(eventos) {
  if (!eventos) return;

  return eventos.map((evento) => ({
    ...evento,
    start: new Date(evento["data_inicio_agenda"]),
    end: DateTime.fromISO(evento["data_fim_evento"])
      .plus({ hour: 1 })
      .toJSDate(),
    status: ["1", "2", "3"][Math.floor(Math.random() * 3)],
  }));
}

export function duracaoEventoEmDias(evento) {
  if (!evento || !evento.start || !evento.end) {
    return;
  }

  return DateTime.fromJSDate(evento.end).diff(
    DateTime.fromJSDate(evento.start),
    ["days"]
  ).days;
}
