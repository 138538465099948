import React, {Fragment, useState} from 'react';
import { connect, useSelector } from 'react-redux';
import {isMobile} from 'react-device-detect';
import HeaderPagina from '../HeaderPagina';
import Card from '../Card';
import ModalTelaCustomizada from '../ModalTelaCustomizada';
import api from "../../services/api";
import { exibeMensagemSucesso, exibeMensagemErro, exibeMensagemInformativa, exibeMensagemAlerta, exibeEmProcessamento, escondeEmProcessamento } from '../../features/aplicacao/aplicacaoSlice';
import PdfViewer from '../PdfViewer';
import { base64ToBlob } from '../../scripts/base64Util';
import { setChaveConsultaCertidaoCND } from '../../features/usuario/usuarioSlice'; 
import Header from '../Header';
import { useHistory } from 'react-router-dom';
import { CardDesativado } from '../Card/desativado';

const mapDispatchToProps = { exibeMensagemSucesso, exibeMensagemErro, exibeMensagemInformativa, exibeMensagemAlerta, exibeEmProcessamento, escondeEmProcessamento, setChaveConsultaCertidaoCND };

function Certidoes (props) {
  
    const caminhoBreadcrumb = [{texto: 'Baixar certidões'}]
    const [exibeCertidao, setExibeCertidao] = useState(false);
    const [certidao, setCertidao] = useState(null);
    const chaveConsultaCertidaoCND = useSelector((state) => state.usuarioStore.chaveConsultaCertidaoCND);
    const usuario = useSelector((state) => state.usuarioStore.usuario);
    const history = useHistory();
    if (usuario.isCNPJ) {
        history.push('/');
    }
   
   const fechaModalCertidao = () => {
        setExibeCertidao(false);
    }
    
    const carregaCertidaoQuitacaoEleitoral = () => {
        props.exibeEmProcessamento({texto: 'Baixando certidão de quitação eleitoral...'});
        api.get('/api/certidoes/quitacao-eleitoral', {responseType: 'blob'})
        .then(response => { 
           const fileURL = URL.createObjectURL(response.data);
           setCertidao(fileURL);
           setExibeCertidao(true);
        })
        .catch(async (erro) => {
            let texto = 'Não foi possível carregar a certidão de quitação eleitoral';
            if (erro.data instanceof Blob && erro.data.type && erro.data.type.toLowerCase().indexOf('json') !== -1) {
                const errorString = JSON.parse(await erro.data.text());
                texto = errorString.message;
            }

            console.log("Não foi possível recuperar a certidão de quitação eleitoral", erro);
            props.exibeMensagemErro({titulo: 'Ocorreu um problema durante o processamento  da certidão de quitação eleitoral:', texto});
        })
        .finally(() => {
            props.escondeEmProcessamento();
        });
    }

    return (
        <Fragment>
          <Header/>  
            <div className="container-lg">
                <ModalTelaCustomizada visivel={exibeCertidao} onClose={fechaModalCertidao}>
                <PdfViewer arquivo={certidao} nomeArquivo="certidao.pdf"/>
                </ModalTelaCustomizada>
            
                <HeaderPagina caminhoBreadcrumb={caminhoBreadcrumb} titulo='Baixar certidões'/>

                <div className='cabecalho_painel'>
                    <p>Emita certidões públicas por meio do portal gov.br</p>
                </div>

                <div className="col">
                    <div className={isMobile ? '' : 'row'}>
                        {/* <Card icone="fa-solid fa-check-to-slot" titulo="Certidão de quitação eleitoral" conteudo="Tribunal Superior Eleitoral"  onClick={carregaCertidaoQuitacaoEleitoral}/> 
                        <Card icone="fa-solid fa-landmark" titulo="Certidão Negativa de Débitos" conteudo="Receita Federal" onClick={carregaCertidaoNegativaDebitos}/>*/}
                        
                        <Card icone="fa-solid fa-landmark" titulo="Certidão Negativa de Débitos" conteudo="Receita Federal"/>
                        <CardDesativado icone="fas fa-wheelchair" titulo="Certidão de Pessoa com Deficiência" conteudo="MDH" onClick={() => {}}>
                            {/* <i className='fa fa-ban' aria-hidden="true" /> */}
                            <span><strong>Certidão temporariamente indisponível neste canal.</strong> Acesse <a style={{textDecoration: 'underline'}} href="https://meu.inss.gov.br/#/certificado-deficiencia" target="_blank" rel="noopener noreferrer">Meu INSS</a> para emitir a certidão.</span>
                        </CardDesativado>

                        <CardDesativado icone="fa-solid fa-file-invoice" titulo="Certificado de Cadastro Único" conteudo="MDS" onClick={() => {}}>
                            {/* <i className='fa fa-ban' aria-hidden="true" /> */}
                            <span><strong>Certidão temporariamente indisponível neste canal.</strong> Acesse o portal do <a style={{textDecoration: 'underline'}} href="https://cadunico.dataprev.gov.br/#/home" target="_blank" rel="noopener noreferrer">Cadastro Único</a> para emitir a certidão.</span>
                        </CardDesativado>
                        {false &&
                        <Fragment>
                        <Card icone="fas fa-wheelchair" titulo="Certidão de pessoa com deficiência" conteudo="Ministério dos Direitos Humanos e da Cidadania" onClick={() => alert('Certidão de pessoa com deficiência')}/>
                        <Card icone="fa-solid fa-house-chimney" titulo="Certidão de faixa de renda familiar" conteudo="Receita Federal" onClick={() => alert('Certidão de faixa de renda familiar')}/>
                        </Fragment>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
    
}

export default connect(null,(mapDispatchToProps))(Certidoes);