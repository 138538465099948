import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue-vivid-80);
  gap: 37px;
  margin-bottom: var(--spacing-scale-3xh);

  > h4 {
    color: var(--blue-warm-vivid-80);
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0px;
    text-transform: capitalize;

    @media (max-width: 575px) {
      font-size: var(--font-size-scale-up-02) !important;
    }
  }

  > button i {
    cursor: pointer;
    color: var(--blue-warm-vivid-80);
    -webkit-text-stroke: 1px var(--blue-warm-vivid-80);

    @media (max-width: 575px) {
      font-size: 14px;
      size: 14px;
    }
  }
`;
