import styled from "styled-components";

export const Container = styled.div`
  > h3 {
    color: var(--blue-vivid-70);
    text-transform: capitalize;
    font-weight: var(--font-weight-bold);
  }

  > ol {
    padding: 0;
  }
`;

export const ItemListaEvento = styled.li`
  @media (max-width: 575px) {
    gap: var(--spacing-scale-base);
  }

  display: flex;
  align-items: start;
  gap: var(--spacing-scale-baseh);
  padding: var(--spacing-scale-3x) 0;

  & + li {
    border-top: var(--surface-border-solid-sm);
    border-color: var(--gray-20);
  }

  > div:first-of-type {
    @media (max-width: 575px) {
      gap: var(--spacing-scale-base);
    }

    display: flex;
    flex-direction: row;
    gap: var(--spacing-scale-baseh);
  }
`;

export const DataItemListaEvento = styled.div`
  width: var(--spacing-scale-10x);
  height: var(--spacing-scale-10x);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: var(--surface-border-solid-md);
  border-color: var(--gray-20);
  border-width: var(--border-width);
  font-size: var(--font-size-scale-up-01);
  color: var(--blue-vivid-70);
  font-weight: var(--font-weight-bold);
  border-radius: var(--surface-rounder-md);
  padding: var(--spacing-scale-base) 0;
  background-color: white;

  > span {
    @media (max-width: 575px) {
      font-size: var(--font-size-scale-base);
    }

    text-transform: uppercase;
    margin: 0px;
    padding: 0px;
  }

  > .separator {
    display: block;
    height: 3px;
    width: 36px;
    border-radius: 4px;
    background-color: var(--gray-20);
    /* margin: var(--spacing-scale-base) 0; */
    margin: 0;
  }
`;

export const SeparadorVertical = styled.span`
  background-color: var(--blue-warm-40);
  width: 3px !important;
  border-radius: var(--surface-rounder-sm);
  flex-shrink: 0;
`;

export const ConteudoItemListaEvento = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: var(--spacing-scale-baseh);
  flex: 1;

  > h5 {
    margin: 0px;
    padding: 0px;
    text-transform: none;
    color: var(--blue-warm-vivid-70);

    /* Line clamp 2 */
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: var(--font-lineheight-medium);

    > a:hover {
      text-decoration: underline;
      background-image: none;
    }
  }

  > p {
    margin-bottom: 0;
    line-height: var(--font-lineheight-low);
  }

  > a {
    color: var(--blue-vivid-50);
    text-decoration: underline;
  }
`;
