import { DateTime } from "luxon";
import api from "./api";
import { mapearParaRBC } from "../Components/PaginaAgendaGovBr/utils";

export const getEventosDoMes = async (dataInicioRange, dataFimRange) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get("/api/agenda", {
        params: {
          dataInicio:
            DateTime.fromJSDate(dataInicioRange).toFormat("yyyy-MM-dd"),
          dataFim: DateTime.fromJSDate(dataFimRange).toFormat("yyyy-MM-dd"),
        },
      });

      if (response.status !== 200) {
        reject(response.status);
      }

      let novosEventos = [...response.data];
      novosEventos = mapearParaRBC(novosEventos).sort(
        (a, b) => new Date(a.start) - new Date(b.start)
      );
      resolve(novosEventos);
    } catch (error) {
      console.error("Não foi possível chamar o serviço corretamente", error);
      reject(error);
    }
  });
};
