import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const SUPPORTED_LANGUAGES = ["en", "es", "pt-BR"];
const FALLBACK_LANGUAGE = "pt-BR";

const detectedLanguage = new LanguageDetector().detect();

export const LANGUAGE = SUPPORTED_LANGUAGES.includes(detectedLanguage)
  ? detectedLanguage
  : FALLBACK_LANGUAGE;

i18next.use(LanguageDetector).init({
  debug: true,
  fallbackLng: ["pt-BR", "en"],
  resources: {
    en: {
      translation: {
        previous: "Back",
        next: "Next",
        today: "Today",
        showMore: (total) => `+${total} moreeeee`,
      },
    },
    es: {
      translation: {
        previous: "Atrás",
        next: "Después",
        today: "Hoy",
        showMore: (total) => `+${total} más`,
      },
    },
    "pt-BR": {
      translation: {
        previous: "Anterior",
        next: "Próximo",
        today: "Hoje",
        showMore: (total) => `+${total} mais`,
      },
    },
  },
});

export default i18next;
