import React from "react";
import { Route} from "react-router-dom";
import { connect } from 'react-redux';
import { RedirectPage } from "../RedirectPage";
import jwt_decode from "jwt-decode";
import config from "../../config";
import { login } from '../../features/usuario/usuarioSlice';
import { getLocalStorage } from "../../scripts/localStoreUtil";
import { strTobase64 } from "../../scripts/base64Util";

const urlGovbr = config.govbrUrl;
const servicoAutorizacaoGovbr = '/authorize';
const response_type = 'code';
const client_id = config.govbrClientId;
const scope = 'openid+(email/phone)+profile+govbr_empresa+govbr_confiabilidades+govbr_wallet+govbr_notificacao';
const redirect_uri = config.govbrRedirectUri;
const nonce = Math.floor(Math.random() * 99999);

const validaToken = (token) => {
	if (token ){
		const expiracaoToken = jwt_decode(token).exp;
		const dataHoraAtual = Date.now();
		if(dataHoraAtual < expiracaoToken){
			return true;
		}
	} 
	return false;
}
	
const mapStateToProps = (state) => {
	return {
		token: state.usuarioStore.token,
	}
}

const mapDispatchToProps = { login }

export const PrivateRoute = 
// redux
connect(
	(mapStateToProps),
	(mapDispatchToProps)
)(
	({component: Component, path, token, login, ...rest }) => {

		const state = strTobase64(JSON.stringify({path}));

		const urlLoginGovbr = urlGovbr + servicoAutorizacaoGovbr 
		+ '?response_type=' + response_type 
		+ '&client_id=' + client_id
		+ '&scope=' + scope
		+ '&redirect_uri=' + redirect_uri
		+ '&nonce=' + nonce
		+ '&state=' + state;
		
		let sessaoAtiva = false;

		const storage = getLocalStorage() !== null ? JSON.parse(getLocalStorage()) : null;

		if(storage) {
			const tokenLocalStorage = storage.token;

			if (validaToken(tokenLocalStorage) ){

				if(! token){
					const foto = storage.foto;
					login({token: tokenLocalStorage, foto});
				}
				sessaoAtiva  = true;
			}
		}

		return (
			<Route
				{...rest}
				render={props => sessaoAtiva ? (<Component {...props} />) : (<RedirectPage to={urlLoginGovbr} />)
			}/>
		);
	}
	
); // redux 