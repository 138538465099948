/* 

Importante instalar a extencao Styled Components para que o arquivo
consiga ser entendido!

componentizacao de estilo GLOBAL

*/
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    /**
    * Algumas variaveis globais que nao foram adicionada no pacote @govbr/dsgov.
    */
    :root {
        // Variaveis de line-height: 
        // https://www.gov.br/ds/fundamentos-visuais/tipografia
        --font-lineheight-low: 1.15;
        --font-lineheight-medium: 1.45;
        --font-lineheight-high: 1.85;
    }

    html, body, #root {
        min-height: 100vh;
        min-width: 100vw;
    }

    body {
        -webkit-font-smoothing: antialiased !important;
    }

    @keyframes pulse {
        50% {
            opacity: .5;
        } 
    }
    
    .skeleton {
        width: 100%;
    }

    .skeleton.line {
     height: 22px;
     border-radius: var(--surface-rounder-sm);
     background: var(--gray-10);
     animation: pulse 2s infinite ease-out;
     color: transparent !important;
     user-select: none;
    }

    .skeleton.square {
     height: 80px;
     border-radius: var(--surface-rounder-sm);
     background: var(--gray-10);
     animation: pulse 2s infinite ease-out;
     color: transparent !important;
     user-select: none;
    }
`;
