import React, { useEffect, useState } from "react";

import { BotaoIrParaOTopo, Container } from "./styles";
import { useRolarPagina } from "../../../hooks/useRolarPagina";

function BotaoRolarParaTopo() {
  const { rolarParaTopo } = useRolarPagina();
  const [mostrarBotao, setMostrarBotao] = useState(false);

  const handleScroll = () => {
    if ((window.scrollY || window.pageYOffset) > 200) {
      setMostrarBotao(true);
    } else {
      setMostrarBotao(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Container
      style={{
        opacity: mostrarBotao ? "1" : "0",
      }}
    >
      <BotaoIrParaOTopo
        type="button"
        onClick={() => rolarParaTopo()}
        className="br-button primary middle circle"
      >
        <i
          aria-hidden="true"
          className="fas fa-chevron-up"
          title="Botão ir para o topo da pagina"
        />
        <span className="sr-only">Botão ir para o topo da pagina</span>
      </BotaoIrParaOTopo>
    </Container>
  );
}

export default BotaoRolarParaTopo;
