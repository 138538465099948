import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import {isMobile} from 'react-device-detect';
import api from "../../services/api";
import { exibeMensagemSucesso, exibeMensagemErro, exibeEmProcessamento, escondeEmProcessamento } from '../../features/aplicacao/aplicacaoSlice';


import Card from '../Card';
import LegendaPainel from '../LegendaPainel';
import { mostraModalAssinaturaDocumentoBronze } from '../../features/aplicacao/aplicacaoSlice';
import config from '../../config';

function addListenersGovBr () {
/*    
    const listCard = []
    for (const [index, brCard] of window.document.querySelectorAll('.br-card').entries()) {
        listCard.push(new BRCard('br-card', brCard, index))
    }
*/
}

const mapDispatchToProps = { mostraModalAssinaturaDocumentoBronze, exibeMensagemSucesso, exibeMensagemErro, exibeEmProcessamento, escondeEmProcessamento };

function PainelServicos  (props) {
    const history = useHistory();
    const usuario = useSelector((state) => state.usuarioStore.usuario);
    const [notificacoesNaoListas, setNotificacoesNaoLidas] = useState(0);

    const onAssinaDocumentoClick = () => {
        if(usuario.isBronze){
            props.mostraModalAssinaturaDocumentoBronze();
        } else {
            window.open(config.assinadorDigitalUrl, "_blank");
        }
    }

    useEffect(() => {
        addListenersGovBr();
        atualizaNotificacoesNaoLidas();
        
        const interval = setInterval(() => {
            atualizaNotificacoesNaoLidas();
        }, config.portalLogadoTempoAtualizarNotificacoesNaoLidas * 1000);
        
        return () => clearInterval(interval); 
     }, []);

     const atualizaNotificacoesNaoLidas = () => {
        api.get('/api/mensagem/nao-lidas')
        .then(response => { 
            const retorno = response.data;
            setNotificacoesNaoLidas(retorno.count);
        })
        .catch(erro => {
            console.log("Não foi possível chamar o serviço corretamente", erro);
            props.exibeMensagemErro({titulo: 'Erro durante a verficação de notificações não lidas:', texto:'Não foi possível verificar a quantidade de mensagens não lidas'});
        })
     }

    return (
            <Fragment>
                <LegendaPainel titulo="Serviços"/>
                
                <div className="col">
                    <div className={isMobile ? '' : 'row'}>
                        { !usuario.isCNPJ && (
                            <>
                                <Card icone="fas fa-wallet" titulo="Carteira de documentos" conteudo="Ver e compartilhar documentos digitais" onClick={() => history.push('./carteira')}/>
                                <Card icone="fas fa-clipboard-check" titulo="Baixar certidões" conteudo="Solicitar certidões públicas" onClick={() => history.push('./certidoes')}/>
                                <Card icone="fas fa-bell" titulo="Notificações" conteudo="Ver notificações recebidas" 
                                    tag={{exibir: notificacoesNaoListas > 0, conteudo: notificacoesNaoListas, tipo:'success'}}
                                    onClick={() => history.push('./notificacoes')}/>
                                <Card icone="fas fa-file-signature" titulo="Assinar documentos digitalmente" conteudo="Utilizar a assinatura eletrônica gov.br" onClick={() => onAssinaDocumentoClick()}/>
                                <Card icone="fas fa-concierge-bell" titulo="Consultar serviços solicitados" conteudo="Veja o andamento das suas solicitações de serviços" onClick={() => history.push('./consultarservicossolicitados')}/>
                                <Card
                                  icone="fas fa-calendar"
                                  titulo="Agenda GOV.BR"
                                  conteudo="Ver agenda de serviços do Governo"
                                  onClick={() => history.push("./agenda")}
                                />
                            </>
                        )}
                        {/* <Card icone="fas fa-concierge-bell" titulo="Serviços solicitados" conteudo="Acompanhar serviços solicitados" onClick={() => window.open(config.servicosSolicitadosUrl, "_blank")}/> */}
                        <Card icone="far fa-question-circle" titulo="Ajuda da conta gov.br" conteudo="Tirar dúvidas sobre a conta gov.br" onClick={() =>  window.open(config.faqGovernoDigitalUrl, "_blank")}/>
                    </div>
                </div>

            </Fragment>
        )
       
}

export default connect(null,(mapDispatchToProps))(PainelServicos);