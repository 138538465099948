import React, { useCallback, useMemo } from "react";
import { DateTime, Settings } from "luxon";
import { formatarDataMesAno } from "../utils";
import { LANGUAGE } from "../../../services/i18n";
import {
  Container,
  ItemListaEvento,
  DataItemListaEvento,
  SeparadorVertical,
  ConteudoItemListaEvento,
} from "./styles";
import { TagAtualizado } from "../TagAtualizado";
import { isMobile } from "react-device-detect";

Settings.defaultLocale = LANGUAGE;

function ListaAgenda(props) {
  const { eventos } = props;
  const labelMesAtual = useMemo(
    () => DateTime.fromJSDate(props.dataAtual).toFormat("MMMM, yyyy"),
    [props.dataAtual]
  );
  const dataParaDiaEMes = useCallback((data) => {
    return DateTime.fromJSDate(data).toFormat("d MMM").replace(/\.$/, "");
  }, []);

  if (!eventos || (Array.isArray(eventos) && eventos.length === 0)) {
    return <></>;
  }

  function getDatasFormatadas(evento) {
    const dataInicioDiaEMes = dataParaDiaEMes(evento.start);
    const dataFimDiaEMes = dataParaDiaEMes(evento.end);

    if (dataInicioDiaEMes === dataFimDiaEMes) {
      return <span className="my-auto">{dataInicioDiaEMes}</span>;
    }

    return (
      <>
        <span>{dataInicioDiaEMes}</span>
        <span className="separator"></span>
        <span>{dataFimDiaEMes}</span>
      </>
    );
  }

  return (
    <>
      <hr className="br-divider" />
      <Container>
        <h3>
          {labelMesAtual ? labelMesAtual : formatarDataMesAno(new Date())}
        </h3>

        <ol>
          {eventos.map((evento) => (
            <ItemListaEvento
              key={`${evento["id_evento"]}-${evento["id_servico"]}`}
              data-evento-uid={`${evento["id_evento"]}-${evento["id_servico"]}`}
            >
              <div>
                <DataItemListaEvento>
                  {getDatasFormatadas(evento)}
                </DataItemListaEvento>

                <SeparadorVertical />
              </div>

              <ConteudoItemListaEvento>
                <h5>
                  <a
                    href={evento["url_evento"]}
                    target={isMobile ? "" : "_blank"}
                    rel={isMobile ? "" : "noopener noreferrer"}
                  >
                    {evento["titulo_servico"]}
                  </a>
                </h5>
                <p>{evento["titulo_evento"]}</p>
                {evento["atualizado"].toUpperCase() === "S" && (
                  <TagAtualizado />
                )}
              </ConteudoItemListaEvento>
            </ItemListaEvento>
          ))}
        </ol>
      </Container>
    </>
  );
}

export default ListaAgenda;
