import styled from "styled-components";

export const Container = styled.div`
  bottom: var(--spacing-scale-2x);
  right: var(--spacing-scale-2x);
  opacity: 0;
  position: fixed;
  z-index: 100;
`;

export const BotaoIrParaOTopo = styled.button`
  cursor: pointer;
`;
