import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

export function useQuery() {
  const location = useLocation();
  const history = useHistory();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const setQuery = (params) => {
    const searchParams = new URLSearchParams(location.search);

    for (let [chave, valor] of Object.entries(params)) {
      searchParams.set(chave, valor);
    }

    history.push({ search: searchParams.toString() });
  };

  return {
    setQuery,
    query,
  };
}
