import { TagAtualizadoContainer } from "./styles";

export function TagAtualizado() {
  return (
    <TagAtualizadoContainer
      className="br-tag bg-green-cool-vivid-50 small"
      aria-describedby="tag-atualizado"
    >
      <span id="tag-atualizado">Atualizado</span>
    </TagAtualizadoContainer>
  );
}
